import D1 from "../../components/ExcerciseTypes/Design/TypeInNoSubmit";
import UnderLine from "../../components/ExcerciseTypes/Design/UnderLine";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";

const json = {
  1: {
    // Exercise num
    unit: "Unit 2",
    id: "LQTAMN1-U2-P11-E1",
    audio: "",
    video: "",
    exerciseKey:
      "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page11/E1/Key/answerKey.PNG",
    component: MatchDots,
    // titleImage: "<img src='https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page19/E2/1.jpg'>",
    titleQuestion: [
      {
        num: "",
        title: `
        
          

        `,
        color: "",
      },
    ],

    question: {
      DrawLines: {
        // multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "234px",
              left: "707px",
              width: 25,
              height: 25,
              borderRadius: "50%",

              // border: "2px solid red",
            },
          },
          //0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "488px",
              left: "724px",
              width: 27,
              height: 27,
              borderRadius: "50%",

              // border: "2px solid red",
            },
          },
          //1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "663px",
              left: "719px",
              width: 27,
              height: 27,
              borderRadius: "50%",

              // border: "2px solid red",
            },
          },
          //2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "185px",
              left: "904px",
              width: 27,
              height: 27,
              borderRadius: "50%",

              // border: "2px solid red",
            },
          },
          //3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "413px",
              left: "883px",
              width: 27,
              height: 27,
              borderRadius: "50%",

              // border: "2px solid red",
            },
          },
          //4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "685px",
              left: "904px",
              width: 27,
              height: 27,
              borderRadius: "50%",

              // border: "2px solid red",
            },
          },
          //5

          //16
        ],
        answers: ["2-3", "0-4", "1-5"],
        initialValue: [],
      },
      Layout: `
      <div style='padding-bottom:100px'>
      <img src='https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page11/E1/1.jpg' />
      <input id='0' type= 'boxMatch' />
      <input id='1' type= 'boxMatch' />
      <input id='2' type= 'boxMatch' />
      <input id='3' type= 'boxMatch' />
      <input id='4' type= 'boxMatch' />
      <input id='5' type= 'boxMatch' />
      </div>
        
          
      `,
    },
  },
  2: {
    // Exercise num
    unit: "Unit 1",
    id: "SB1-U1-P11-E2",
    audio:
      "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page11/Audio/sing.e2.mp3",
    video: "",
    songLyric:
      "<p>It’s red.<p>" +
      "<p>It’s black.<p>" +
      "<p>It’s green.<p>" +
      "<p>It’s blue.<p>" +
      "<p>Lots of colors for me and you.<p>" +
      "<p>It’s red.<p>" +
      "<p>It’s black.<p>" +
      "<p>It’s green.<p>" +
      "<p>It’s blue.<p>" +
      "<p>Lots of colors for me and you.<p>",
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    questionImage: [
      // Row
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page11/E2/1.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page11/E2/2.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page11/Audio/tieude.e2.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page11/E2/3.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page11/E2/4.jpg",
        },
      ],
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 1",
    id: "SB1-U1-P11-E3",
    audio: "",
    video: "",
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    question: [],
    questionImage: [
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page11/E3/1.jpg",
        },
      ],
    ],
  },
  4: {
    // Exercise num
    unit: "Unit 1",
    id: "SB1-U1-P11-E4",
    audio: "",
    video: "",
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    question: [],
    questionImage: [
      [
        {
          url: "https://cdn.sachso.edu.vn/mn1/img/FriendsPlus/Page11/E4/1.jpg",
        },
      ],
    ],
  },
};

export default json;
